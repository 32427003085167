<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" label-width="120px" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getQmttsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="物料编号:">
                <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="产品编号:">
                <el-input size="small" v-model.trim="searchForm.prod_no" clearable placeholder="请填写产品工单编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="产品订单编号:">
                <el-input size="small" v-model.trim="searchForm.podr_no" clearable placeholder="请填写产品订单编号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="物料名称:">
                <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料订单编号:"> 
                <el-input size="small" v-model.trim="searchForm.modr_no" clearable placeholder="请填写物料订单编号"></el-input>
              </el-form-item>
            </el-col>
             <el-col :md="8">
              <el-form-item  label="潘通色号:">
                <el-input size="small" v-model.trim="searchForm.mtrl_color"  clearable placeholder="请填写潘通色号"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
           
            <el-col :md="14">
              <el-form-item label="金检时间：">
                  <el-date-picker
                  size="small"
                  v-model="searchForm.timeValue"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getQmttsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
      </div>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" v-loading="loadingFlag" :data="tableData" border>
            <el-table-column label="物料编号" prop="mtrl_no"/>
             <el-table-column label="产品编号" prop="prod_no">
              <template slot-scope="scope">
                 <span v-if="scope.row.prod_no">{{scope.row.prod_no}}</span>  
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="产品订单编号" prop="podr_no">
              <template slot-scope="scope">
                 <span v-if="scope.row.podr_no">{{scope.row.podr_no}}</span>  
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column> 
             <el-table-column label="物料订单编号" prop="modr_no">
              <template slot-scope="scope">
                 <span v-if="scope.row.modr_no">{{scope.row.modr_no}}</span>  
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column> 
            <!-- modr_no -->
            <el-table-column label="物料图片" width="100px" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.imge_url">
                <el-image class="vd_elimg"  fit="fill"  :src='formatPic(scope,"s")'></el-image>
              </span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </template>
            </el-table-column>
            <el-table-column label="物料名称" prop="mtrl_name"/>
            <el-table-column label="潘通色号" prop="colr_html">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_color">
                  <div class="vd_dis">
                    <div :class="{'vd_div':scope.row.colr_html}" :style="{'background-color': scope.row.colr_html}"></div>
                    <span>{{ scope.row.mtrl_color}}</span> 
                  </div>
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料门幅" prop="mtrl_width"> 
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_width">
                  {{ scope.row.mtrl_width | formaUnitM}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料毛高" prop="mtrl_thick">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_thick">
                  {{ scope.row.mtrl_thick | formaUnitH}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="产品数量" prop="qmtt_num" show-overflow-tooltip :formatter="formatterNum"/>
            <el-table-column label="不通过数量" prop="qmtt_failnum"></el-table-column>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina"/>
            <el-table-column label="金检时间" prop="create_time" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.create_time">
                  {{ scope.row.create_time | formatDate}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="单据状态" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
              </template>
            </el-table-column> -->
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {qmttAPI} from "@api/modules/qmtt"
import pubPagination from "@/components/common/pubPagination";
import helper from "@assets/js/helper.js"
export default {
name: "qmttList",
  components: {
    pubPagination,
  },
  data() {
    return {
      searchForm: {
        podr_no:null,
        mtrl_no: null,
        mtrl_color:'',
        mtrl_name:null,
        modr_no:null,
        prod_no:null,
        timeValue:{
          startTime:null,
          endTime:null
        }
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      tableData1: [],
      tableDataList:[],
      multiSelection: [],
      btn:{},
      loadingFlag:true,
       imgUpdatatime:parseInt(Math.random()*100000000),
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/qmtt_edit'){
        this.initData()
      }
    },
  },
  filters:{
    // 产品类型转换
    formatQmttType(row){
      if(row === 1){
        return '新款'
      }else if(row === 2){
        return '老款'
      }else if(row === 3){
        return '修改款'
      }
    },
    // 时间转换
    formatDate(row) {
      return helper.toStringDate(row)
    },
    formaUnitM(row){
      return helper.haveFour(row)+'米'
    },
    formaUnitH(row){
      return helper.reservedDigits(row)+'毫米'
    },
  },
  methods: {
    initData(){
      this.loadingFlag = true
      this.getQmtts();
    },
    // 获取产品信息
    getQmtts() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(qmttAPI.getQmtts,
          {
            podr_no:this.searchForm.podr_no,
            prod_no:this.searchForm.prod_no,
            mtrl_name: this.searchForm.mtrl_name,
            mtrl_no:this.searchForm.mtrl_no,
            page_no: this.currentPage,
            mtrl_color:this.searchForm.mtrl_color,
            modr_no:this.searchForm.modr_no,
            start_time: timeNewVal.startTime,
            end_time: timeNewVal.endTime,
          })
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total
              this.btn = res.data.data.btn;
              setTimeout(() => {
                this.loadingFlag = false
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        mtrl_no:null,
      };
      this.loadingFlag = true
      this.currentPage = 1
      this.initData()
      this.$refs.pubPagination.currentPage = 1
    },
    // 查询方法
    getQmttsNow(){
      this.loadingFlag = true
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.initData()
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.initData()
    },
    // 数量显示
    formatterNum(row){
      return this.helper.haveFour(row.qmtt_num)
    },
    // 双击row跳转到详情编辑
    dbClickJp(row){
      const permId = this.$route.query.perm_id
      this.jump('/qmtt_edit',{perm_id:permId,form_id:row.podr_prod_id})
    },
     // 图片类型
    formatPic(scope,type){
      let updateTime = this.imgUpdatatime
      return this.helper.picUrl(scope.row.imge_url,type,updateTime,scope.row.imge_source)
    },
  }
}
</script>

<style scoped lang="scss">
.vd_button_group{
  width: 100vw;
  position: relative;
}
.vd_txta {
  position: absolute;
  left: 35%;
}
::v-deep .vd_tenpd{
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_img{
  margin-top: 5px;
  width: 40px;
}
.vd_notpass{
  color: rgb(187, 187, 187);
}
.vd_pass{
  color: #188dff;
}
.vd_complate{
  color: #36ab60;
}
.vd_disable{
  color:#d81e06;
}
.vd_wei{
  color: #ff8511;
}
.vd_div{
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
.vd_dis{
  display: flex;
}
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
</style>